import React, { Component } from "react";
import {isIOS} from 'react-device-detect';


export class Redirect extends Component {
  constructor( props ){
    super();
    this.state = { ...props };
  }
  componentWillMount(){
    if (isIOS){
      window.location = "https://apps.apple.com/co/app/quqo/id1491808711";
    }else{
      window.location = "https://play.google.com/store/apps/details?id=com.quqo";
    }
    
  }
  render(){
    return (<section>Redirecting...</section>);
  }
}

export default Redirect;
